import { default as _91id_93PNTrreuyYlMeta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/[id].vue?macro=true";
import { default as BookErrorComponent4ND90YdQ3NMeta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/components/BookErrorComponent.vue?macro=true";
import { default as BookInfoComponentOzZCZuU6RuMeta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/components/BookInfoComponent.vue?macro=true";
import { default as BookNotFoundCck8wTQaMRMeta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/components/BookNotFound.vue?macro=true";
import { default as BookshelfEmptyStatemz1BNQwETkMeta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/components/BookshelfEmptyState.vue?macro=true";
import { default as PendingBookComponent4efIIBIP7OMeta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/components/PendingBookComponent.vue?macro=true";
import { default as indexUhXTW8qyl0Meta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/index.vue?macro=true";
import { default as PicModalKZipWlQblfMeta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/camera/components/PicModal.vue?macro=true";
import { default as indexa8PIWFMeiBMeta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/camera/index.vue?macro=true";
import { default as indexWe5VgXYFMsMeta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/index.vue?macro=true";
import { default as loginSkeSMsS852Meta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/login.vue?macro=true";
import { default as logout89z3zFEOAYMeta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/logout.vue?macro=true";
import { default as index8WMgV0LyO3Meta } from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/search/index.vue?macro=true";
export default [
  {
    name: "bookshelf-id",
    path: "/bookshelf/:id()",
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/[id].vue").then(m => m.default || m)
  },
  {
    name: "bookshelf-components-BookErrorComponent",
    path: "/bookshelf/components/BookErrorComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/components/BookErrorComponent.vue").then(m => m.default || m)
  },
  {
    name: "bookshelf-components-BookInfoComponent",
    path: "/bookshelf/components/BookInfoComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/components/BookInfoComponent.vue").then(m => m.default || m)
  },
  {
    name: "bookshelf-components-BookNotFound",
    path: "/bookshelf/components/BookNotFound",
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/components/BookNotFound.vue").then(m => m.default || m)
  },
  {
    name: "bookshelf-components-BookshelfEmptyState",
    path: "/bookshelf/components/BookshelfEmptyState",
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/components/BookshelfEmptyState.vue").then(m => m.default || m)
  },
  {
    name: "bookshelf-components-PendingBookComponent",
    path: "/bookshelf/components/PendingBookComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/components/PendingBookComponent.vue").then(m => m.default || m)
  },
  {
    name: "bookshelf",
    path: "/bookshelf",
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/bookshelf/index.vue").then(m => m.default || m)
  },
  {
    name: "camera-components-PicModal",
    path: "/camera/components/PicModal",
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/camera/components/PicModal.vue").then(m => m.default || m)
  },
  {
    name: "camera",
    path: "/camera",
    meta: indexa8PIWFMeiBMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/camera/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexWe5VgXYFMsMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginSkeSMsS852Meta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout89z3zFEOAYMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("D:/PROGRAMAS/trash-main/trash5/guis/shelfie/pages/search/index.vue").then(m => m.default || m)
  }
]