import payload_plugin_BlGmaLUnCJ from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/nuxt-vuefire@1.0.3_@firebase+app-types@0.9.2_firebase-admin@11.11.1_encoding@0.1.13__firebase_glogswxvxk7xpnecpowuues3te/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_r8hwq3n4eC from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Ot8VaAofGt from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hoG5t7yRFD from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_i5H92LPui9 from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FSjDqGnaO1 from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qltHaWYuR1 from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3SzZBABLnE from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/.nuxt/components.plugin.mjs";
import prefetch_client_NQQpRCDeLE from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.10.0_jiti@1.21_vxilcrm7aercbzb43og36vj3vm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/.nuxt/pwa-icons-plugin.ts";
import pwa_client_EES5uvwVBL from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_terser@5.32_wchxrre3eeufuyi3ao6iwha6rm/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_LwNRCnHeam from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/nuxt-vuefire@1.0.3_@firebase+app-types@0.9.2_firebase-admin@11.11.1_encoding@0.1.13__firebase_glogswxvxk7xpnecpowuues3te/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/.nuxt/vuefire-plugin.mjs";
import slideovers_wnbMccniyQ from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.7.7_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_terse_mtbpiv5bzhsyzzndg4k3ovjkry/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_mAM9kkWVzU from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.7.7_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_terse_mtbpiv5bzhsyzzndg4k3ovjkry/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ryD6NcIgpr from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.7.7_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_terse_mtbpiv5bzhsyzzndg4k3ovjkry/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_88cpzxDXld from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.5_rollup@4.21.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_A2HuxJjFET from "D:/PROGRAMAS/trash-main/trash5/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_terser@5.32.0__vue@3.5.3_typescript@5.5.4_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import vue_query_wrmMkNpEpe from "D:/PROGRAMAS/trash-main/trash5/guis/shelfie/plugins/vue-query.ts";
export default [
  payload_plugin_BlGmaLUnCJ,
  revive_payload_client_r8hwq3n4eC,
  unhead_Ot8VaAofGt,
  router_hoG5t7yRFD,
  payload_client_i5H92LPui9,
  navigation_repaint_client_FSjDqGnaO1,
  check_outdated_build_client_qltHaWYuR1,
  chunk_reload_client_3SzZBABLnE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NQQpRCDeLE,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_EES5uvwVBL,
  plugin_client_LwNRCnHeam,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  slideovers_wnbMccniyQ,
  modals_mAM9kkWVzU,
  colors_ryD6NcIgpr,
  plugin_client_88cpzxDXld,
  plugin_A2HuxJjFET,
  vue_query_wrmMkNpEpe
]